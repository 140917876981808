import {useRef,useState} from 'react';
import './App.css';

function App() {
  const refContainer = useRef(null);
  const [val, setVal] = useState();

  const enter = ()=>{
    if (refContainer.current.value == '') {
      setVal('');
      return;
    }

    let val = parseInt(refContainer.current.value);
    let letter;
    if (val <= 15) {
      letter = 'B';
    } else if (val <= 30) {
      letter = 'I';
    } else if (val <= 45) {
      letter = 'N';
    } else if (val <= 60) {
      letter = 'G';
    } else {
      letter = 'O';
    }
    setVal(letter + ' ' + val);
    var msg = new SpeechSynthesisUtterance();
    msg.text = letter + ' ' + val;
    window.speechSynthesis.speak(msg);
    refContainer.current.value = '';
    refContainer.current.focus()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      enter();
    }
  }

  return (
    <div className="App">
      <div className="io">
        <input ref={refContainer} type="text" onKeyDown={handleKeyDown}></input>
        <button onClick={enter}>OK</button>
      </div>
      <div id="picked">
        {val}
      </div>
    </div>
  );
}

export default App;
